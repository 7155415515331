import React, { useState } from "react";
import Button from "@mui/material/Button";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";

const CopyButton = ({ text, handleFuc }) => {
	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		if (handleFuc) {
			let textFc = handleFuc();

			navigator.clipboard
				.writeText(textFc)
				.then(() => {
					setCopied(true);
					setTimeout(() => {
						setCopied(false);
					}, 2000);
				})
				.catch((error) => {
					console.error("Error copying text:", error);
				});
		} else {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					setCopied(true);
					setTimeout(() => {
						setCopied(false);
					}, 2000);
				})
				.catch((error) => {
					console.error("Error copying text:", error);
				});
		}
	};

	return (
		<Button sx={{ minWidth: 90 }} variant="contained" onClick={handleCopy} disabled={copied}>
			{copied ? 'Copied' : "Copy"}
		</Button>
	);
};

export default CopyButton;
