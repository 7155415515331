import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, TextField, Dialog, FormControl, Card, CardContent, Autocomplete } from "@mui/material";

import { fetchPostData } from "../../helper/fetch-data";
import SkypeGroupEditor from "./skype-group-editor/skype-group-editor";
import { useSnackbar } from "notistack";
import CopyButton from "../../Components/CopyButton";
import RemoveIcon from "@mui/icons-material/Remove";
import _,{ debounce } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import unidecode from "unidecode";

const boxCellStyle = { width: "100%", minWidth: 200, mt: "3px" };

const fetchSkypeGroupData = async () => {
	const result = await fetchPostData(
		new URL("./api/transferGetData", process.env.REACT_APP_HOST_MASTER_API).href,
		{
			urlAPI: "/api/group-skype/get-list",
			pageCurrent: "/esearch/skypebot/wtb",
			filter: {},
		},
		{
			"Content-Type": "application/json",
			Authorization: "Bearer " + process.env.REACT_APP_TOKEN_KEY,
		}
	);
	return result;
};

const fetchSearchProductHint = async (searchKey) => {
	const result = await fetchPostData(
		new URL("./api/transferGetData", process.env.REACT_APP_HOST_MASTER_API).href,
		{
			urlAPI: "/api/product-model/search-product-price",
			filter: {
				where: {
					_q: searchKey,
				},
			},
			pageCurrent: "/esearch/skypebot/wtb",
		},
		{
			"Content-Type": "application/json",
			Authorization: "Bearer " + process.env.REACT_APP_TOKEN_KEY,
		}
	);
	return result;
};

//fetch data
const fetchSubmitModelData = async (payload) => {
	const result = await fetchPostData(new URL("./api/send-group-skype", process.env.REACT_APP_HOST_FOR_SKYPE).href, payload, {
		"Content-Type": "application/json",
		Autho: process.env.REACT_APP_TOKEN_KEY_FOR_SKYPE_API,
	});
	return result;
};

const fetchSaveDraftWtb = async (payload) => {
	const result = await fetchPostData(new URL("./api/save-wtb-on-group", process.env.REACT_APP_HOST_FOR_SKYPE).href, payload, {
		"Content-Type": "application/json",
		Autho: process.env.REACT_APP_TOKEN_KEY_FOR_SKYPE_API,
	});
	return result;
};

const fetchGetWTBdataByPassCodeUser = async (passCodeUser) => {
	const result = await fetchPostData(
		new URL("./api/transferGetData", process.env.REACT_APP_HOST_MASTER_API).href,
		{
			urlAPI: "/api/wtbquote/get-list-extend",
			filter: {
				where: {
					passCodeUser,
				},
			},
			// pageCurrent: "/esearch/skypebot/wtb"
		},
		{
			"Content-Type": "application/json",
			Authorization: "Bearer " + process.env.REACT_APP_TOKEN_KEY,
		}
	);
	return result;
};

//==================================================

function WtbDialog({ setOpenWTBSkypeGroup }) {
	const searchHintTimer = useRef({
		currentTimer: -1,
	});

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [formDataGroupSkypeId, setFormDataGroupSkypeId] = useState([]);

	const [isDisableSubmitButton, setDisableSubmitButton] = useState(false);

	const [isOpenSkypeGroup, setIsOpenSkypeGroup] = useState(false);

	const [skypeGroupData, setSkypeGroupData] = useState([]);

	const [autoCompleteModel, setAutoCompleteModel] = useState([]);

	const [inputCode, setInputCode] = useState("");

	const [wtbQuoteIdData, setWtbQuoteIdData] = useState("");

	const [isChecked, setisChecked] = useState(false);

	const [debouncedFunction, setDebouncedFunction] = useState(null);

	const [debounceGetCodeId, setDebounceGetCodeId] = useState(false);

	const [dataSkyGroupUser, setDataSkyGroupUser] = useState([]);

	const [countFetch, setCountFetch] = useState(0);

	const [isFetchDone, setIsFetchDone] = useState(false);

	const [dataFetch, setDataFetch] = useState();

	const [inputHint, setInputHint] = useState("");

	/**
	 * @type {[
	 *  {
	 *      rowId?: string,
	 *      productId?: string,
	 *      productCode?: string,
	 *      condition?: string,
	 *      qty?: string,
	 *      note?: string,
	 *  }[],
	 *  any
	 * ]}
	 */
	const [modelList, setModelList] = useState([]);

	const formData = useRef({
		/**
		 * @type {string[]}
		 */
		groupSkypeId: [],

		modelList: [],
	});

	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (debounceGetCodeId) {
			var timeoutDebounceCodeId = setTimeout(() => {
				setDebounceGetCodeId(false);
			}, 2000);
		}
		return () => {
			clearTimeout(timeoutDebounceCodeId);
		};
	}, [debounceGetCodeId]);

	//Clear WTB id
	useEffect(() => {
		if (inputCode === "") {
			setWtbQuoteIdData();
		}
	}, [inputCode]);

	const initData = async () => {
		await getSkypGroupData();

		modelList.length = 0;
		createNewModelRow();
	};

	const getSkypGroupData = async () => {
		const fetchList = await fetchSkypeGroupData();
		if (!fetchList || typeof fetchList?.data?.length !== "number") return;
		setSkypeGroupData(fetchList.data);

		// in next event loop, remove skype group that is non exist
		// removeInvalidSkypeGroup(fetchList.data);
	};

	/**
	 * remove non-exist skype group
	 */
	// const removeInvalidSkypeGroup = (currentSkypeGroupData = skypeGroupData) => {
	// 	for (let i = 0; i < formDataGroupSkypeId.length; i++) {
	// 		const data = formDataGroupSkypeId[i];
	// 		if (currentSkypeGroupData.find((x) => x.groupSkypeId === data)) continue;
	// 		formDataGroupSkypeId.splice(i, 1);
	// 		i--;
	// 	}
	// 	setFormDataGroupSkypeId([...formDataGroupSkypeId]);
	// };

	const submitModelData = async () => {
		// removeInvalidSkypeGroup();

		let payload = {
			wtbQuoteId: wtbQuoteIdData,
			passCodeUser: inputCode,
		};

		// if (!wtbQuoteIdData) {
			payload.wtbQuoteId = await handleSaveDraftApi();
		// }

		// send api
		try {
			const res = await fetchSubmitModelData(payload);
			if (!res || res.Status !== "OK") throw Error(res.Msg || "Submit Fail");
			setOpenWTBSkypeGroup(false);
			enqueueSnackbar(`Send successful`, { variant: "success" });
		} catch (error) {
			enqueueSnackbar(error.message || `Submit fail`, { variant: "error" });
		}
		setDisableSubmitButton(false);
	};

	const createNewModelRow = (isClear) => {
		const id = Date.now() + "." + Math.floor(Math.random() * 10000);

		const dataInit = {
			rowId: id,
			productId: "",
			productCode: "",
			condition: ["USED"],
			qty: 0,
			note: "",
		};

		if (isClear) {
			setModelList([dataInit]);
		} else {
			setModelList((pre) => [...pre, dataInit]);
		}
	};

	const editModelRow = (rowId, newData, addRow) => {
		const targetRowIndex = modelList.findIndex((x) => x.rowId === rowId);
		if (targetRowIndex === -1) return;

		const targetRow = modelList[targetRowIndex];

		for (const key in newData) {
			if (key === "condition") {
				if (targetRow["condition"]?.includes(newData[key])) {
					targetRow["condition"] = targetRow["condition"].filter((val) => val !== newData[key]);
				} else {
					targetRow["condition"] = [...targetRow[key], newData[key]];
				}
			} else {
				targetRow[key] = newData[key];
			}
		}

		if (targetRowIndex === modelList.length - 1 && addRow) createNewModelRow();
	};

	const deleteModelRow = async (rowId) => {
		const targetRowIndex = modelList.findIndex((x) => x.rowId === rowId);
		if (targetRowIndex === -1) return;

		modelList.splice(targetRowIndex, 1);
		setModelList([...modelList]);
	};

	const getSearchProductHint = async (searchKey) => {
		if (searchHintTimer.current.currentTimer !== -1) {
			clearTimeout(searchHintTimer.current.currentTimer);
		}

		let newTimerId = setTimeout(async () => {
			const searchData = await fetchSearchProductHint(searchKey);
			if (!searchData || typeof searchData?.data?.length !== "number") return;

			if (searchHintTimer.current.currentTimer !== newTimerId) return;

			searchHintTimer.current.currentTimer = -1;

			setAutoCompleteModel(
				searchData.data.map((x) => ({
					label: x.code,
					id: x.id,
				}))
			);
		}, 300);

		searchHintTimer.current.currentTimer = newTimerId;
	};

	useEffect(() => {
		const debouncedAPICall = _.debounce((value) => {
			getSearchProductHint(inputHint);
		}, 500);

		debouncedAPICall(inputHint);

		return () => {
			debouncedAPICall.cancel();
		};
	}, [inputHint]);

	const handleClearAllButtonClick = () => {
		createNewModelRow(true);
		handleSaveDraftApi(true);
		setDataSkyGroupUser([]);
	};

	// Copy
	const handleCopyClick = () => {
		const arr = [...modelList];
		const [last, ...rest] = arr.reverse();
		return rest
			.reverse()
			.map((item) => {
				if (item.condition.length > 1) {
					return item.condition.map((el) => `${item.qty === "" ? 0 : item.qty}x ${item.productCode} ${el} Note: ${item.note}`).join("\n");
				} else {
					return `${item.qty === "" ? 0 : item.qty}x ${item.productCode} ${item.condition[0]} Note: ${item.note}`;
				}
			})
			.join("\n");
	};

	//Handle code input
	// useEffect(() => {
	// 	const timerId = setTimeout(() => {
	// 	}, 500);

	// 	return () => clearTimeout(timerId);
	// }, [inputCode]);

	const handleChangeCodeInput = (event) => {
		setInputCode(event.target.value);
	};

	const handleGetWTBCodeUser = async () => {
		try {
			if (inputCode && !debounceGetCodeId) {
				const res = await fetchGetWTBdataByPassCodeUser(inputCode);
				if (wtbQuoteIdData && res?.data?.length) {
					// enqueueSnackbar(`Code user already exists!`, { variant: "error" });
				} else if (res?.data?.length) {
					const dataRes = res?.data[0].wtbQuoteDetails?.map((el) => ({
						qty: el.total,
						rowId: el.id,
						note: el.note,
						productId: el.productId,
						productCode: el.productCode,
						condition: el.condition?.includes("") ? el.condition?.filter((item) => item !== "") : el.condition,
					}));

					setModelList(dataRes);
					setWtbQuoteIdData(res?.data[0]?.id ?? "");
					setDataSkyGroupUser(res?.data[0]?.groupSkypeId);
					setDebounceGetCodeId(true);
					createNewModelRow();

					// enqueueSnackbar(`Get data from code '${inputCode}' successful`, { variant: "success" });
				} else if (!res?.data?.length && wtbQuoteIdData) {
					handleSaveDraftApi();
				} else {
					enqueueSnackbar(`Code user dont exit`, { variant: "error" });
				}
			}
		} catch (error) {
			enqueueSnackbar(error.message || `Code user dont exit`, { variant: "error" });
		}
	};

	const handleKeyPressCodeInput = (event) => {
		if (event.key === "Enter") {
			handleGetWTBCodeUser();
		}
	};
	//Handle save draft Data

	useEffect(() => {
		if (countFetch && isFetchDone) {
			handleSaveDraftApi();
		}
	}, [countFetch, isFetchDone]);

	useEffect(() => {
		if (dataFetch) {
			setCountFetch((pre) => (pre === 0 ? 0 : pre - 1));
			setIsFetchDone(true);
		}
	}, [dataFetch]);

	//call api submit
	// useEffect(() => {
	// 	if(countFetch === 0 && isDisableSubmitButton) submitModelData();
	// },[countFetch,isDisableSubmitButton])

	const handleSaveDraftApi = async (isClear) => {
		try {
			let payload = {
				wtbQuoteId: wtbQuoteIdData,
				passCodeUser: inputCode,
				groupSkypeId: isClear ? [] : dataSkyGroupUser,
				modelList: isClear
					? []
					: modelList.slice(0, -1).map(({ productId, productCode, condition, qty, note }) => ({ productId, productCode, condition, qty, note })),
			};
			const res = await fetchSaveDraftWtb(payload);
			setDataFetch(res);
			if (!res || res.Status !== "OK") throw Error(res.Msg || "Submit Fail");
			setWtbQuoteIdData(res?.wtbQuoteId);

			// enqueueSnackbar(`Send daft wtbID ${res?.wtbQuoteId} successful`, { variant: "success" });
			return res?.wtbQuoteId;
		} catch (error) {
			enqueueSnackbar(error.message || `Submit fail`, { variant: "error" });
		}
	};
	//Handle autocomplete
	const handleBlur = () => {
		if (modelList?.length > 1 && modelList.slice(0, -1).every((el) => el.productCode !== "")) {
			if (countFetch === 0) {
				handleSaveDraftApi();
			}

			setCountFetch(countFetch + 1);
			setIsFetchDone(false);
		}
	};

	// handle input
	const handleChangeInput = (index, name, value) => {
		setModelList((pre) => {
			const arr = [...pre];

			arr[index][name] = value;
			return arr;
		});
	};

	//debounce check input
	useEffect(() => {
		// const handleChange = () => {
		// 	const timeout = setTimeout(() => {
		if (isChecked) {
			handleBlur();
		}
		// 	}, 500);

		// 	return timeout;
		// };

		// if (debouncedFunction) {
		// 	clearTimeout(debouncedFunction);
		// }

		// const debounced = debounce(handleChange, 500);
		// setDebouncedFunction(debounced);

		// debounced();

		// return () => {
		// 	if (debouncedFunction) {
		// 		clearTimeout(debouncedFunction);
		// 	}
		// 	debounced.cancel();
		// };
	}, [isChecked]);

	const renderTableRows = () => {
		return modelList?.map((row, index) => (
			<tr key={row?.rowId} className="mytr">
				{/* Model */}
				<td className="text-center">
					<Box
						sx={{
							...boxCellStyle,
							// maxWidth: 300, minWidth: 300
							width: { xs: 80, md: 100, lg: 400 },
						}}>
						<Autocomplete
							freeSolo
							id="combo-box-demo"
							options={autoCompleteModel}
							filterOptions={(row) => row}
							size="small"
							renderInput={(params) => <TextField required id="outlined-basic" variant="outlined" {...params} size="small" />}
							sx={{
								width: "100%",
								"& .MuiInputBase-input": { height: "0.5rem" },
								// '& .MuiInputLabel-root': {transform: "translate(14px,9px)"}
							}}
							onFocus={(event) => {
								setAutoCompleteModel([]);
								setInputHint("");
							}}
							onInputChange={(event, newInputValue) => {
								const convertedValue = unidecode(newInputValue);

								const upperCaseText = convertedValue?.toString()?.toUpperCase();

								const editData = {
									productCode: upperCaseText,
								};
								editModelRow(row.rowId, { ...editData }, true);
								setInputHint(upperCaseText);
							}}
							onBlur={handleBlur}
							value={row?.productCode ?? ''}
							inputValue={row?.productCode ?? ""}
							onChange={(event, newValue) => {
								const dataOnchange = { productCode: newValue?.label !== "" ? newValue?.label : inputHint, productId: newValue?.id };
								editModelRow(row.rowId, dataOnchange);
							}}
						/>
					</Box>
				</td>

				{/* Qty */}
				<td>
					<Box sx={{ ...boxCellStyle, width: 65, minWidth: 65 }}>
						<TextField
							onChange={(event) =>
								// editModelRow(row.rowId, { qty: event.target.value })
								handleChangeInput(index, "qty", event.target.value)
							}
							sx={{
								"& .MuiInputBase-input": { height: "0.5rem", width: "50%" },
								"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
									display: "none",
								},
								"& input[type=number]": {
									MozAppearance: "textfield",
								},
							}}
							id="outlined-basic"
							variant="outlined"
							type="number"
							size="small"
							disabled={index >= modelList.length - 1}
							InputProps={{
								inputProps: { min: 0 },
							}}
							onBlur={handleBlur}
							value={row?.qty}
						/>
					</Box>
				</td>
				{/* Note */}
				<td>
					<Box
						sx={{
							...boxCellStyle,
							//  minWidth: 250
							// width: {xs: 80, md: 200, lg: 320}
						}}>
						<TextField
							onChange={(event) =>
								// editModelRow(row.rowId, { note: event.target.value })
								handleChangeInput(index, "note", event.target.value)
							}
							id="outlined-basic"
							variant="outlined"
							size="small"
							sx={{ "& .MuiInputBase-input": { height: "0.5rem", width: { xs: 100, md: 150, lg: 250 } } }}
							disabled={index >= modelList.length - 1}
							onBlur={handleBlur}
							value={row?.note}
						/>
					</Box>
				</td>

				{/* Condition */}
				<td>
					<Box sx={{ ...boxCellStyle, minWidth: 100 }}>
						<FormControl
							sx={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "nowrap",
								"& .MuiCheckbox-root": { padding: "2px" },
								gap: 1,
								padding: 0,
							}}
							disabled={index >= modelList.length - 1}>
							<FormControlLabel
								value="USED"
								control={<Checkbox />}
								label="USED"
								checked={row?.condition?.includes("USED")}
								onChange={(event, value) => {
									editModelRow(row.rowId, { condition: event.target.value });
									setisChecked(Math.random());
									setModelList((pre) => {
										const arr = [...pre];
										if (value && Array.isArray(arr[index].condition)) {
											arr[index].condition = arr[index].condition?.includes("USED") ? arr[index].condition : arr[index].condition.push("USED");
										} else {
											arr[index].condition = arr[index].condition?.filter((el) => el !== "USED");
										}
										return arr;
									});
								}}
							/>
							<FormControlLabel
								value="NOB"
								control={<Checkbox />}
								label="NOB"
								checked={row?.condition?.includes("NOB")}
								onChange={(event, value) => {
									editModelRow(row.rowId, { condition: event.target.value });
									setisChecked(Math.random());
									setModelList((pre) => {
										const arr = [...pre];
										if (value && Array.isArray(arr[index].condition)) {
											arr[index].condition = arr[index].condition?.includes("NOB") ? arr[index].condition : arr[index].condition.push("NOB");
										} else {
											arr[index].condition = arr[index].condition.filter((el) => el !== "NOB");
										}
										return arr;
									});
								}}
							/>
							<FormControlLabel
								value="NIB"
								control={<Checkbox />}
								label="NIB"
								checked={row?.condition?.includes("NIB")}
								onChange={(event, value) => {
									editModelRow(row.rowId, { condition: event.target.value });
									setisChecked(Math.random());
									setModelList((pre) => {
										const arr = [...pre];
										if (value && Array.isArray(arr[index].condition)) {
											arr[index].condition = arr[index].condition?.includes("NIB") ? arr[index].condition : arr[index].condition.push("NIB");
										} else {
											arr[index].condition = arr[index].condition.filter((el) => el !== "NIB");
										}
										return arr;
									});
								}}
							/>
						</FormControl>
					</Box>
				</td>

				{/* Action */}
				<td className="text-center" style={{ maxWidth: "50px" }}>
					<button
						style={{ padding: 0, backgroundColor: "transparent", borderColor: "transparent" }}
						disabled={index >= modelList.length - 1}
						onClick={(e) => {
							deleteModelRow(row.rowId);
							handleBlur();
							if (index === 0) {
								handleSaveDraftApi(true);
							}
						}}>
						<RemoveIcon size="large" color="error" sx={{ p: 0 }} />
					</button>
				</td>
			</tr>
		));
	};

	return (
		<>
			<Box sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", md: "row" } }}>
				<div>
					<TextField
						onChange={handleChangeCodeInput}
						onKeyPress={handleKeyPressCodeInput}
						onBlur={handleBlur}
						id="outlined-basic"
						variant="outlined"
						size="small"
						sx={{
							"& .MuiInputBase-input": { height: "0.5rem", padding: "8px 0 8px 8px" },
							mt: "28px",
							width: { xs: 200, md: 100, lg: 150 },
							"& .MuiInputBase-root": { pr: 0 },
						}}
						value={inputCode}
						placeholder="Enter code"
						InputProps={{
							endAdornment: (
								<InputAdornment
									onClick={() => {
										setInputCode("");
									}}
									sx={{
										"& .MuiSvgIcon-root": { fontSize: "1rem", mr: "8px", display: inputCode ? "inline-block" : "none" },
										cursor: "pointer",
									}}
									position="end">
									<CloseIcon />
								</InputAdornment>
							),
						}}
					/>
				</div>
				<div style={{ pt: 1 }}>
					<Box className="tableFixHead">
						<table className="table table-hover table-responsive table-bordered" style={{ padding: "0 !important" }}>
							<thead>
								<tr>
									<th scope="col">Model</th>
									<th scope="col">Qty</th>
									<th scope="col">Note</th>
									<th scope="col">Cond</th>
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody>{renderTableRows()}</tbody>
						</table>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-around",
							gap: 1,
						}}>
						<Card variant="outlined" sx={{ width: "100%", p: 0 }}>
							<CardContent sx={{ p: "2px 2px !important", "& .MuiButton-root": { height: 25 } }}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "flex-start",
										flexWrap: "nowrap",
										height: 28,
									}}>
									<div className="flex-center">
										<label>SKYPE: </label>
									</div>
									<div className="flex-start" style={{ width: "100%", marginLeft: "50px" }}>
										<FormControl
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "flex-start",
												flexWrap: "nowrap",
												"& .MuiCheckbox-root": { padding: "2px" },
												gap: 1,
												padding: 0,
											}}>
											{skypeGroupData
												.filter((el) => !el.hidden)
												.map((x) => (
													<FormControlLabel
														onChange={(event, checked) => {
															if (checked) {
																// setFormDataGroupSkypeId([...formDataGroupSkypeId, x.groupSkypeId]);
																setDataSkyGroupUser((pre) => [...pre, x?.groupSkypeId]);
															} else {
																setDataSkyGroupUser((pre) => pre.filter((el) => el !== x?.groupSkypeId));
															}
															// setisChecked(Math.random());
															// const indexToDelete = formDataGroupSkypeId.findIndex((value) => value === x.groupSkypeId);
															// if (indexToDelete === -1) return;
															// formDataGroupSkypeId.splice(indexToDelete, 1);
															// setFormDataGroupSkypeId([...formDataGroupSkypeId]);
															handleBlur();
														}}
														sx={{ p: 0 }}
														value={x.id}
														key={x.id}
														control={<Checkbox />}
														label={x.name}
														checked={dataSkyGroupUser?.includes(x?.groupSkypeId)}
													/>
												))}
										</FormControl>
									</div>

									<Dialog
										open={isOpenSkypeGroup}
										onClose={() => {
											getSkypGroupData();
											setIsOpenSkypeGroup(false);
										}}
										// maxWidth={true}
									>
										<SkypeGroupEditor skypeGroupData={skypeGroupData} getSkypGroupData={getSkypGroupData}></SkypeGroupEditor>
									</Dialog>
								</Box>
								<Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
									<Button onClick={() => setIsOpenSkypeGroup(true)} variant="outlined">
										Config
									</Button>

									<Button variant="contained" color="error" onClick={handleClearAllButtonClick}>
										Clear
									</Button>

									<CopyButton text={"tets"} handleFuc={handleCopyClick} />

									<Button
										onClick={(event) => {
											setDisableSubmitButton(true);
											submitModelData();
										}}
										sx={{ minWidth: "89px" }}
										variant="contained"
										disabled={
											isDisableSubmitButton ||
											!modelList?.slice(0, -1).every((el) => el.condition?.length) ||
											modelList.length < 2 ||
											!dataSkyGroupUser?.length 
											// !!countFetch
											// !inputCode
										}>
										SEND
									</Button>
								</Box>
							</CardContent>
						</Card>
					</Box>
				</div>
			</Box>
			{/* <DialogActions>
              <Button
              // onClick={handleClose}
              >Cancel</Button>
              <Button type="submit">Subscribe</Button>
            </DialogActions> */}
		</>
	);
}

export default WtbDialog;
