export const fetchPostData = async (url, data, headers = {}) => {
    try {
        const response = await fetch(
            url,
            {
                method: 'POST',
                headers: headers,

                body: JSON.stringify(data)
            }
        );
        if (!response.ok) {
            console.log('Network response was not ok');
            return;
        }
        return await response.json();
    } catch (error) {
        console.log('error fetch', error);
    }
};